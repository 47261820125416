<template>
  <ul
    class="d-flex flex-column"
    style="row-gap: .4rem"
  >
    <template v-if="!isSwGroup">
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in getNavItems"
        :key="`${item.header || item.title} ${item.title}`"
        :item="item"
      />
    </template>

    <template v-else>
      <template
        v-for="item in getNavItems.filter(item => !item.hide)"
      >
        <vertical-nav-menu-link
          :id="`root_item_${item.key}_${item.title}`"
          :key="`root_item_${item.key}-${item.hide}`"
          v-b-tooltip.hover.right
          :item="item"
          :title="isVerticalMenuCollapsed ? $t(item.title) : ''"
          class="w-100"
        />
      </template>

      <vertical-nav-menu-link
        v-if="getNavItems.find(item => item.hide)"
        :id="`root_menu_item_999_more`"
        ref="navSubMenuButton"
        :key="`root_item_999_more`"
        v-b-tooltip.hover.right
        :title="isVerticalMenuCollapsed ? $t('More') : ''"
        :item="{
          title: 'More',
          icon: 'MoreHorizontalIcon',
        }"
        @click="$emit('click', getNavItems.filter(item => item.hide))"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import {
  BLink, BNavItemDropdown, BPopover, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import userAppConfig from '@core/app-config/useAppConfig'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    BLink,
    BNavItemDropdown,
    BPopover,
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    nav: {
      type: Array,
      required: true,
    },
  },
  emits: ['click'],
  setup() {
    provide('openGroups', ref([]))

    const { isSwGroup, isVerticalMenuCollapsed } = userAppConfig()

    return {
      resolveNavItemComponent,
      isSwGroup,
      isVerticalMenuCollapsed,
    }
  },
  data: () => ({
    modal: null,
    navMenuItems,
    interval: null,
  }),
  computed: {
    store() {
      return store
    },
    getNavItems() {
      const items = this.nav.map(n => {
        const ni = this.navMenuItems.find(nii => nii.key === n.key)

        return {
          ...ni,
          ...n,
          tag: 0,
        }
      }).filter(route => this.routeFilter(route)).filter(Boolean).sort(((a, b) => a.position - b.position))

      if (!this.interval && items.find(({ key }) => ['chat', 'client-chat'].includes(key))) {
        this.setChatsUnreadMessages(items)
      }

      return items
    },
  },
  methods: {
    async setChatsUnreadMessages() {
      await this.$store.dispatch('verticalMenu/GET_GLOBAL_INFO')

      if (!this.interval) {
        this.interval = setInterval(() => {
          this.setChatsUnreadMessages()
        }, 20000)
      }
    },
    routeFilter(route) {
      let allow = true
      if (route?.requiredModule?.length) {
        const settings = this.$store.getters['system/getSettings']
        const modules = route.requiredModule.filter(module => !!settings[module])
        allow = modules.length >= 1
        if (!allow) return false
      }

      if (route?.requiredPermissions?.length && route?.viewPermissions?.length && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions) && this.checkRequiredViewPermissions(...route.viewPermissions)
      }

      if (!route?.requiredPermissions?.length && route?.viewPermissions?.length && allow) {
        return this.checkRequiredViewPermissions(...route.viewPermissions)
      }

      if (route?.requiredPermissions?.length && allow) {
        return this.checkRequiredPermissions(...route.requiredPermissions)
      }

      return true
    },
  },
}
</script>
