<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        icon="SettingsIcon"
        size="15"
        class="spinner"
      />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
      <div>
        <h4 class="text-uppercase mb-0">
          {{ $t('Customizer.ThemeCustomizer') }}
        </h4>
      </div>
      <feather-icon
        icon="XIcon"
        size="18"
        class="cursor-pointer"
        @click="isCustomizerOpen = !isCustomizerOpen"
      />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="ps-customizer-area scroll-area"
    >
      <!-- Skin, RTL, Router Animation -->
      <div class="customizer-section">

        <!-- Skin -->
        <b-form-group :label="$t('Customizer.Skin')">
          <b-form-radio-group
            id="skin-radio-group"
            v-model="skin"
            name="skin"
          >
            <b-form-radio
              v-for="({ text, value }, index) in skinOptions"
              :key="`skin_${index}`"
              :value="value"
              variant="primary"
            >
              {{ $t(`Customizer.Skins.${text}`) }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <!-- Router Transition -->
        <sw-select
          :name="$t('Customizer.AnimationOfTheView')"
          label-cols="6"
        >
          <v-select
            v-model="routerTransition"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            label="title"
            :options="routerTransitionOptions"
            :reduce="option => option.value"
          >
            <template #no-options>
              {{ $t('NoOptions') }}
            </template>
            <template #option="{ title }">
              {{ $t(`Customizer.Animations.${title}`) }}
            </template>
            <template #selected-option="{ title }">
              {{ $t(`Customizer.Animations.${title}`) }}
            </template>
          </v-select>
        </sw-select>
      </div>
      <!-- /Skin, RTL, Router Animation -->

      <!-- SECTION: Menu -->
      <div class="customizer-section">

        <!-- Layout Type -->
        <!--        <b-form-group-->
        <!--          label="Menu"-->
        <!--        >-->
        <!--          <b-form-radio-->
        <!--            v-for="(layoutTypeItem, index) in layoutTypeOptions"-->
        <!--            :key="`layout_type_item_${index}`"-->
        <!--            v-model="layoutType"-->
        <!--            variant="primary"-->
        <!--            name="layout-type"-->
        <!--            :value="layoutTypeItem.value"-->
        <!--          >-->
        <!--            {{ $t(`Customizer.Layouts.${layoutTypeItem.text}`) }}-->
        <!--          </b-form-radio>-->
        <!--        </b-form-group>-->

        <!-- Collapsible -->
        <div
          v-if="layoutType === 'vertical'"
          class="d-flex justify-content-between align-items-center mt-50"
        >
          <span class="font-weight-bold">{{ $t('Customizer.NavbarCollapsed') }}</span>
          <b-form-checkbox
            v-model="isVerticalMenuCollapsed"
            variant="primary"
            name="is-vertical-menu-collapsed"
            class="mr-0"
            switch
            inline
          />
        </div>
      </div>

      <!-- SECTION: Navbar -->
      <div class="customizer-section">

        <!-- Navbar Color -->
        <!--        <b-form-group-->
        <!--          v-show="layoutType === 'vertical'"-->
        <!--          :label="$t('Customizer.NavbarColor')"-->
        <!--        >-->
        <!--          <div-->
        <!--            v-for="(color, index) in navbarColors"-->
        <!--            :key="color"-->
        <!--            class="p-1 d-inline-block rounded mr-1 cursor-pointer"-->
        <!--            :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': navbarBackgroundColor === color}]"-->
        <!--            @click="navbarBackgroundColor = color"-->
        <!--          />-->
        <!--        </b-form-group>-->

        <!-- Navbar Type -->
        <b-form-group :label="$t('Customizer.NavbarType')">
          <b-form-radio-group
            v-model="navbarType"
            name="navbar-type"
          >
            <div
              v-for="(item, index) in navbarTypes"
              :key="`navbar_type_${index}`"
              class="mt-25"
            >
              <b-form-radio
                v-if="item"
                variant="primary"
                :value="item.value"
              >
                {{ $t(`Customizer.Types.${item.text}`) }}
              </b-form-radio>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </div>

      <div v-if="checkRequiredHostnameFragments(['campter', '4b'])"
           class="customizer-section"
      >
        <b-form-group :label="$t('ContactVariant')">
          <b-form-radio-group
            v-model="contactViewVariant"
            name="navbar-type"
          >
            <b-form-radio
              variant="primary"
              value="new"
            >
              {{ $t(`New`) }}
            </b-form-radio>
            <b-form-radio
              variant="primary"
              value="old"
            >
              {{ $t(`Old`) }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BFormCheckbox, BFormGroup, BFormRadio, BFormRadioGroup, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from './useAppCustomizer'

export default {
  components: {
    // BSV
    BLink,
    BFormRadioGroup,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,

    // 3rd party
    vSelect,
    VuePerfectScrollbar,
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Components
      isAvatarLink,
      avatarTarget,
      targets,
      contactViewVariant,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s?.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t?.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions: skinOptions.filter(s => s?.text),

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Components
      isAvatarLink,
      avatarTarget,
      targets,
      contactViewVariant,
    }
  },
  watch: {
    contactViewVariant(nw) {
      const { contactId, threadId, tab } = this.$route.params

      let url = ''
      if (contactId && threadId) {
        url = `/${nw === 'new' ? 'new_contact' : 'contact'}/${contactId}/thread/${threadId}/${tab || 'timeline'}`
      } else if (contactId) {
        url = `/${nw === 'new' ? 'new_contact' : 'contact'}/${contactId}`
      }

      if (nw === 'new') {
        const isOld = this.$route.name === 'contact'

        if (isOld && url) {
          this.$router.push(url)
        }
      } else {
        const isNew = this.$route.name === 'new_contact'
        if (isNew && url) {
          this.$router.push(url)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
  padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

  .dark-layout & {
    border-color: $theme-dark-border-color;
  }

  #skin-radio-group ::v-deep {
    .custom-control-inline {
      margin-right: 0.7rem;
    }
  }

  .form-group {
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    ::v-deep legend {
      font-weight: 500;
    }
  }
}

.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

.ps-customizer-area {
  height: calc(100% - 83px)
}
</style>
